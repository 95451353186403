<script>
    import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
    export default {
        name:'PzBaseForm2',
        comments: [],
        mixins: [ baseFormFlujo],
        props: {

            jubilado: {
                type: Boolean,
                default: true,
            },
            monto: {
                type: Boolean,
                default: true,
            },
            plazo: {
                type: Boolean,
                default: true,
            },
            tea: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                reference:'formulario2',
                centerDialogVisible: false,
            }
        }
        ,
        created() {

        },
        methods: {
        },
    }
</script>


